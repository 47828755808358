import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system';
import Spinner from '../common/Spinner';
import { style } from '../../variables/Variables.jsx';
import { getClients, deleteClient, getClientByCompId, changeLicence, getUnseenLogs } from '../../actions/clientActions';
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import CountUp from 'react-countup';
import TextFieldGroup from '../common/TextFieldGroup';
import { getCurrentProfile } from '../../actions/profileAction';

class ClientTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: '',
      _notificationSystem: null,
      notificationShown: false,
      compVatId: '',
      compId: '',
      deviceSerialNo: '',
      vendorName: '',
      licenceId:'',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getUnseenLogs();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.notificationShown == false) {
      this.setState({ notificationShown: true })
      this.setState({ _notificationSystem: this.refs.notificationSystem });
      var _notificationSystem = this.refs.notificationSystem;
      if (nextProps.client.allUnseenLogs.length > 0) {
        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-note2" />,
          message: (
            <div>
              <b>
                Novih odgovora u logovima{' '}
                {
                  nextProps.client.allUnseenLogs.length
                }
              </b>
            </div>
          ),
          level: 'warning',
          position: 'tr',
          autoDismiss: 15
        });
      } else {
        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-note2" />,
          message: (
            <div>
              <b>
                Trenutno nema novih odgovora
              </b>
            </div>
          ),
          level: 'warning',
          position: 'tr',
          autoDismiss: 15
        });
      }
    }

  }

  onDeactivateClick(cbLicenceId) {
    Swal.fire({
      title: 'Dektiviraj licencu?',
      text: "Da li sigurno želite da deaktivirate licencu ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Deaktiviraj!'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.changeLicence(cbLicenceId,  'Neaktivna'))
      }
    })
  }
  onChangeActivate(cbLicenceId) {
    Swal.fire({
      title: 'Aktiviraj licencu?',
      text: "Da li sigurno želite da aktivirate licencu ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Aktiviraj!'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.changeLicence(cbLicenceId,  'Aktivna'))
      }
    })
  }

  onKeyUpCheck = e => {
    if (e.key === 'Enter') {
      if (this.state.compId.length > 2) { this.props.getClientByCompId(this.state.compId.trim(), "compId") }
      else if (this.state.compVatId.length > 2) { this.props.getClientByCompId(this.state.compVatId.trim(), "compVatId") }
      else if (this.state.compName.length > 2) { this.props.getClientByCompId(this.state.compName.trim(), "compName") }
      else if (this.state.deviceSerialNo.length > 2) { this.props.getClientByCompId(this.state.deviceSerialNo.trim(), "deviceSerialNo") }
      else if (this.state.vendorName.length > 2) { this.props.getClientByCompId(this.state.vendorName.trim(), "vendorName") }
      else if (this.state.licenceId.length>2){this.props.getClientByCompId(this.state.licenceId.trim(),"cbLicenceId")}
    }
  }

  updateSearchClick = () => {
    if (this.state.compId.length > 2) { this.props.getClientByCompId(this.state.compId.trim(), "compId") }
    else if (this.state.compVatId.length > 2) { this.props.getClientByCompId(this.state.compVatId.trim(), "compVatId") }
    else if (this.state.compName.length > 2) { this.props.getClientByCompId(this.state.compName.trim(), "compName") }
    else if (this.state.deviceSerialNo.length > 2) { this.props.getClientByCompId(this.state.deviceSerialNo.trim(), "deviceSerialNo") }
    else if (this.state.vendorName.length > 2) { this.props.getClientByCompId(this.state.vendorName.trim(), "vendorName") }
    else if (this.state.licenceId.length>2){this.props.getClientByCompId(this.state.licenceId.trim(),"cbLicenceId")}
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { loading } = this.props.client;
    const clients = this.props.client.clients;
    var aktivni = 0;
    var neaktivni = 0;
    let clientItems;
    if (clients === null || loading) {
      clientItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    }
    else {
      if (clients.length > 0) {
        const count = clients.length;
        var countActive = 0;
        var countInactive = 0;
        for (let i = 0; i < count; i++) {
          if (clients[i].statusLicence === 'Aktivna') {
            countActive += 1;
          }
          else if (clients[i].statusLicence === 'Neaktivna') {
            countInactive += 1;
          }
        }
        aktivni = countActive;
        neaktivni = countInactive;

        clientItems = clients.map(client => (
          <tr key={client._id}>
            <td style={{ wordBreak: 'break-word' }}>{client.activationDate.split('T00:00:00.000Z')}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
            <td>{client.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.userEmail}</td>
            <td>
              O: {client.userPhone}
            <br/>
              K: {client.compPhone}
            </td>
            <td>{client.vendorNameCode} - {client.vendorName}</td>
            <td>{client.deviceSerialNo}</td>
            <td>Sim: {(() => {
              switch (client.simCard) {
                case 'Da':
                  return (
                    <span>
                      {client.simCard}
                    </span>
                  );
                default:
                  return (
                    <span>
                      Ne
                    </span>
                  );
              }
            })()}
            <br/>
            KP: {(() => {
              switch (client.payment) {
                case 'Aktivno':
                  return (
                    <span>
                      Aktivno
                    </span>
                  );
                case 'Suspendovano':
                  return (
                    <span>
                      Suspendovano
                    </span>
                  );
                default:
                  return (
                    <span>
                      Neaktivno
                    </span>
                  );
              }
            })()}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.deviceAddrCity}, {client.deviceAddress}</td>
            
            <td>{client.statusLicence === 'Aktivna' ? (
              <button
                type="button"
                className="btn-fill btn btn-sm btn-success"
                onClick={this.onDeactivateClick.bind(this, client.cbLicenceId)}
              >
                Aktivan
              </button>
            ) :
              <button
                type="button"
                className="btn-fill btn btn-sm btn-danger"
                onClick={this.onChangeActivate.bind(this, client.cbLicenceId)}
              >
                Neaktivan
              </button>}
            </td>
            <td style={{ wordBreak: 'break-word' }}>{(() => {
              switch (true) {
                case client.cbLicenceId != null:
                  return (
                    <span>
                      {client.cbLicenceId}
                    </span>
                  );
                case client.cbLicenceId == null:
                  return (
                    <span>
                      Uređaj nije povezan sa licencom
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td style={{width: '8%'}}>
              <Row>
                <div className="btn-group mb-4">
                  <Link
                    to={`/clients/add-client/${client._id}`}
                    className="btn btn-fill btn-info btn-sm"
                  >Izmena
                  </Link>
                </div>
                <div className="btn-group mb-4">
                  <Link
                    to={`/clients/ClientCallHistory/${client._id}`}
                    className="btn btn-fill btn-secondary btn-sm"
                  >Logovi
                  </Link>
                </div>
                <div className="btn-group mb-4">
                  <Link
                    type="button"
                    className="btn-fill btn btn-sm btn-danger"
                    to={`/clients/remove-client/${client._id}`}
                  >Izbrisi
                  </Link>
                </div>
                <div className="btn-group mb-4">
                  <Link
                    to={`/clients/history-table/${client.cbLicenceId}`}
                    className="btn btn-fill btn-info btn-sm"
                  >Istorija
                  </Link>
                </div>
              </Row>
            </td>
          </tr>
        ));
      }
      else {
        clientItems = (
          <tr>
            <td>Licenca sa tim podacima nije pronađena...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Row className='justify-content-center'>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-check text-success" />}
              statsText="Aktivni"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={aktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Broj aktivnih uređaja"
            />
          </Col>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-calculator text-info" />}
              statsText="Svi"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={aktivni + neaktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Ukupan broj svih uređaja"
            />
          </Col>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-close-circle text-danger" />}
              statsText="Neaktivni"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={neaktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Broj neaktivnih uređaja"
            />
          </Col>
        </Row>
        <Container fluid>
          <form onKeyUp={this.onKeyUpCheck.bind(this)}>
            <Row className='justify-content-center'>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compName}
                  placeholder="Ime kompanije"
                  name="compName"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compVatId}
                  placeholder="Pib"
                  name="compVatId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compId}
                  placeholder="MB"
                  name="compId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.deviceSerialNo}
                  placeholder="Serijski broj"
                  name="deviceSerialNo"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.vendorName}
                  placeholder="Sifra distributera"
                  name="vendorName"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.licenceId}
                  placeholder="ID licence"
                  name="licenceId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <Col md={2}>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="button"
                      value="Pretrazi"
                      onClick={this.updateSearchClick.bind(this)}
                      className="btn-fill col-md-12 pull-center btn btn-info"
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
          <br></br>
          <Row>
            <Col md={12}>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover id="table-to-xls" >
                    <thead>
                      <tr>
                        <th>Datum fisk.</th>
                        <th>Ime kompanije</th>
                        <th>PIB (VatId)</th>
                        <th>Email kontakt osobe</th>
                        <th>Telefon</th>
                        <th>Vendor</th>
                        <th>SN</th>
                        <th>Dodatno</th>
                        <th>Adresa uređaja</th>
                        <th />
                        <th>LicenceId</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{clientItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}

ClientTable.propTypes = {
  deleteClient: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientByCompId: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  getUnseenLogs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  client: state.client,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getClients, deleteClient, getClientByCompId, changeLicence, getCurrentProfile, getUnseenLogs }
)(ClientTable);