import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getDeletedClients } from '../../actions/clientActions';
import Card from '../../components/Card/Card';
import { getCurrentProfile } from '../../actions/profileAction';
import axios from 'axios';

class DeletedClientsDistTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			clients: []
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange = d => {
		this.setState({
			search: d.target.value
		}, () => { console.log(this.state.search) })
	}

	componentDidMount() {
		this.props.getCurrentProfile();
		const vendorName = this.props.auth.user.NavCode;

		axios.post(`/api/vendor/getDeletedClientsByVendor/${vendorName}`)
			.then(response => {
				this.setState({ clients: response.data })
			})
			.catch(err => {
				console.log(err)
			})
	}


	render() {
		const { loading } = this.props.deleted;
		const clients = this.state.clients.filter(deletedclients => {
			return (
				deletedclients.deviceSerialNo.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
				deletedclients.compVatId.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
			)
		});
		let removedClientsDist;
		if (clients === null || loading) {
			removedClientsDist = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);
		}

		else {
			if (clients.length > 0) {
				removedClientsDist = clients.map(client => (
					<tr key={client._id}>
						<td>{client.dateOfActivation.split('T00:00:00.000Z')}</td>
						<td>{client.dateOfRemoval.split('T00:00:00.000Z')}</td>
						<td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
						<td>{client.compVatId}</td>
						<td>{client.deviceSerialNo}</td>
						<td>{client.reason}</td>
					</tr>
				));
			}
			else {
				removedClientsDist = (
					<tr>
						<td>Licenca sa tim podacima nije pronađena...</td>
					</tr>
				);
			}
		}

		return (
			<div className="content">
				<Container fluid>
					<Row>
						<Col md={12}>
							<input
								className="form-control form-control-lg col-md-11"
								type="text"
								placeholder="Pretraga licenci..."
								name="search"
								style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
								onChange={this.onChange.bind(this)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card
								title="Licence"
								category="Informacije o licencama"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover id="table-to-xls" >
										<thead>
											<tr>
												<th>Datum aktivacije</th>
												<th>Datum brisanja</th>
												<th>Ime kompanije</th>
												<th>Pib</th>
												<th>Serijski broj uređaja</th>
												<th>Razlog brisanja</th>
											</tr>
										</thead>
										<tbody>{removedClientsDist}</tbody>
									</Table>
								}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

DeletedClientsDistTable.propTypes = {
	deleted: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	getDeletedClients: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	deleted: state.deleted,
	client: state.client,
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ getDeletedClients, getCurrentProfile }
)(DeletedClientsDistTable);
